import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import useSafari from '~utils/useSafari'

const AnimateInView = ({ className, children, variants, threshold }) => {
	const controls = useAnimation()
	const [ref, inView] = useInView({threshold: threshold ? threshold : 0})
	const safari = useSafari()

	useEffect(() => {
		if (inView) {
			controls.start('visible')
		}
	}, [controls, inView])

	if(safari){
		return <div className={className}>{children}</div>
	}

	return (
		<motion.div 
			className={className}
			ref={ref}
			animate={controls}
			initial="hidden"
			transition={{ duration: 0.9, ease: [0.25, 1, 0.5, 1], delay: 0.5 }}
			variants={variants}
		>
			{children}
		</motion.div>
	)
}

AnimateInView.propTypes = {
	className: PropTypes.string,
	children: PropTypes.object,
	variants: PropTypes.object,
	threshold: PropTypes.number,
}

export default AnimateInView